<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.admin.user.headers.detail') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.id') }}:</b-td>
                                    <b-td>{{ data.id }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.username') }}:</b-td>
                                    <b-td>{{ data.userName }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.name') }}:</b-td>
                                    <b-td>{{ data.firstName }} {{ data.lastName }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.email') }}:</b-td>
                                    <b-td>{{ data.email }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.role') }}:</b-td>
                                    <b-td>{{ data.roles ? data.roles.map(item => item.name[locale]).join(', ') : '' }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.created') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.admin.user.form.updated') }}:</b-td>
                                    <b-td>{{ $moment(data.updatedAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <hr>
                <h4>{{ $t('pages.admin.user.headers.resources') }}</h4>
                <b-row>
                    <b-col sm="6" md="8" xl="8" class="my-1">
                        <b-button size="sm" class="mr-1" variant="orange" v-b-modal:add-modal>
                            <font-awesome-icon icon="plus"/>
                            {{ $t('pages.admin.user.buttons.resourceAdd') }}
                        </b-button>
                        <b-button :disabled="selectedItems.length === 0" variant="danger" class="mr-1" size="sm" v-b-modal.delete-modal>
                            <font-awesome-icon icon="trash-alt"/>
                            {{ $t('pages.admin.user.buttons.resourceDelete') }}
                        </b-button>
                    </b-col>
                    <b-col sm="6" md="4" xl="4" class="my-1">
                        <b-form-group
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="$t('generally.tables.tipToSearch')"
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-table
                            :items="items"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            @filtered="onFiltered"
                            :empty-text="$t('generally.tables.emptyText')"
                            :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                            stacked="md"
                            show-empty
                            striped
                            selectable
                            select-mode="multi"
                            ref="resourceTable"
                            class="bg-white"
                            @row-selected="onSelected"
                            :busy="tableLoading"
                            sort-by="name"
                        >

                            <template #table-busy>
                                <div class="text-center my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                </div>
                            </template>

                            <template #head(selected)="{}">
                                <template v-if="selectAll">
                                    <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'square']"/></span>
                                </template>
                            </template>
                            <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                                    <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true"><font-awesome-icon :icon="['far', 'square']"/></span>
                                    <span class="sr-only">Not selected</span>
                                </template>
                            </template>

                            <template #cell(name)="row">
                                {{ convertName(row.item.name) }}
                            </template>

                            <template #cell(resourceType)="row">
                                {{ row.item.resourceType }}
                            </template>

                            <template #cell(public)="row">
                                <font-awesome-icon icon="users" v-if="row.item.public"/>
                            </template>

                            <template #cell(expiresAt)="row">
                                <span v-if="row.item.expiresAt">{{ $moment(row.item.expiresAt).format('DD.MM.YYYY HH:mm') }}</span>
                            </template>

                            <template #cell(createdAt)="row">
                                {{ $moment(row.item.createdAt).format('DD.MM.YYYY HH:mm') }}
                            </template>

                            <template #cell(actions)="row">
                                <b-button variant="info" class="btn-xs mb-0 ml-1" :title="$t('buttons.show')" @click="onShow(row.item)" v-b-modal.info-modal>
                                    <font-awesome-icon icon="eye"/>
                                </b-button>
                                <b-button variant="orange" v-if="row.item.expiresAt" class="btn-xs mb-0 ml-1" :title="$t('buttons.edit')" @click="onEdit(row.item)">
                                    <font-awesome-icon icon="clock"/>
                                </b-button>
                            </template>

                            <template #row-details="row">
                                <b-card>
                                    <ul>
                                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                    </ul>
                                </b-card>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="right"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </b-col>
                </b-row>
                <b-modal
                    id="info-modal"
                    ref="info-modal"
                    :title="$t('pages.admin.user.headers.detailResource')"
                    :cancel-title="$t('buttons.cancel')"
                    size="lg"
                >
                    <b-table-simple v-if="viewedItem !== null" striped small>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ $t('pages.admin.user.table.resources.id') }}</b-td>
                                <b-td>{{ viewedItem.id }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('pages.admin.user.table.resources.name') }}</b-td>
                                <b-td>{{ convertName(viewedItem.name) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('pages.admin.user.table.resources.type') }}</b-td>
                                <b-td>{{ viewedItem.resourceType }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('pages.admin.user.table.resources.public') }}</b-td>
                                <b-td>
                                    <font-awesome-icon :class="[viewedItem.public ? 'text-success' : 'text-danger']" :icon="viewedItem.public ? 'check' : 'times'"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('pages.admin.user.table.resources.expiresAt') }}</b-td>
                                <b-td><span v-if="viewedItem.expiresAt">{{ $moment(viewedItem.expiresAt).format('DD.MM.YYYY HH:mm') }}</span></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('pages.admin.user.table.resources.createdAt') }}</b-td>
                                <b-td>{{ $moment(viewedItem.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <div v-if="viewedItem && viewedItem.resourceType === 'pools'">
                        <hr>
                        <pool-details v-if="!viewedItemLoading" :data="viewedItem"/>
                    </div>
                    <template #modal-footer="{ hide }">
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button size="sm" variant="secondary" @click="hide('forget')">
                            {{ $t('buttons.close') }}
                        </b-button>
                    </template>
                </b-modal>
                <b-modal
                    id="add-modal"
                    ref="add-modal"
                    :title="$t('pages.admin.user.headers.addResource')"
                    :cancel-title="$t('buttons.cancel')"
                    size="lg"
                >
                    <b-overlay :show="resourceModalLoading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            :label="$t('pages.admin.keyManagement.form.resourceType')"
                        >
                            <b-form-select
                                v-model="resourceForm.resourceType"
                                :options="resourceTypes"
                                @change="onLoadResources"
                                @keypress.enter.stop
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            :label="$t('pages.admin.keyManagement.form.resource')"
                        >
                            <v-select
                                :options="resources"
                                v-model="resourceForm.resource"
                                :class="resourceFormState.resource === false ? 'is-invalid' : ''"
                                :get-option-label="(option) => option.name"
                                @keypress.enter.stop
                            ></v-select>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            :label="$t('pages.admin.keyManagement.form.expireResourceType')"
                        >
                            <b-form-select
                                v-model="resourceForm.expireResourceType"
                            >
                                <!--<b-form-select-option value="never">{{ $t('pages.admin.keyManagement.values.expireResourceType.never') }}</b-form-select-option>-->
                                <b-form-select-option value="date">{{ $t('pages.admin.keyManagement.values.expireResourceType.date', {date: resourceForm.expireResourceDate ? $moment(resourceForm.expireResourceDate).format('DD.MM.YYYY') : 'DD.MM.YYYY'}) }}</b-form-select-option>
                                <b-form-select-option value="days">{{ $t('pages.admin.keyManagement.values.expireResourceType.days', {days: resourceForm.expireResourceDays}) }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            v-show="resourceForm.expireResourceType === 'days'"
                            :label="$t('pages.admin.keyManagement.form.expireResource.days')"
                        >
                            <b-input-group>
                                <b-form-input
                                    v-model="resourceForm.expireResourceDays"
                                    type="number"
                                    :state="resourceFormState.expireResourceDays"
                                />
                                <b-input-group-append>
                                    <b-input-group-text>{{ $t('pages.admin.keyManagement.form.days') }}</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            v-show="resourceForm.expireResourceType === 'date'"
                            :label="$t('pages.admin.keyManagement.form.expireResource.date')"
                        >
                            <b-input-group>
                                <b-form-datepicker
                                    v-model="resourceForm.expireResourceDate"
                                    hide-header
                                    :state="resourceFormState.expireResourceDate"
                                    v-bind="$t('modules.datepicker') || {}"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :min="new Date()"
                                ></b-form-datepicker>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            v-show="resourceForm.expireResourceType === 'date'"
                        >
                            <b-input-group>
                                <b-form-timepicker
                                    v-model="resourceForm.expireResourceTime"
                                    :state="resourceFormState.expireResourceTime"
                                    locale="de"
                                    no-close-button
                                    hide-header
                                    v-bind="$t('modules.timepicker') || {}"
                                ></b-form-timepicker>
                            </b-input-group>
                        </b-form-group>
                    </b-overlay>
                    <template #modal-footer="{ hide }">
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button size="sm" variant="secondary" @click="hide('forget')">
                            {{ $t('buttons.close') }}
                        </b-button>
                        <b-button size="sm" variant="orange" @click="onSubmitAddResource">
                            {{ $t('buttons.add') }}
                        </b-button>
                    </template>
                </b-modal>
                <b-modal
                    id="edit-modal"
                    ref="edit-modal"
                    :title="$t('pages.admin.user.headers.editResource')"
                    :cancel-title="$t('buttons.cancel')"
                    size="lg"
                >
                    <b-overlay :show="resourceModalLoading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                            :label="$t('pages.admin.keyManagement.form.expireResource.date')"
                        >
                            <b-input-group>
                                <b-form-datepicker
                                    v-model="resourceForm.expireResourceDate"
                                    hide-header
                                    :state="resourceFormState.expireResourceDate"
                                    v-bind="$t('modules.datepicker') || {}"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :min="new Date()"
                                ></b-form-datepicker>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="5"
                        >
                            <b-input-group>
                                <b-form-timepicker
                                    v-model="resourceForm.expireResourceTime"
                                    :state="resourceFormState.expireResourceTime"
                                    locale="de"
                                    no-close-button
                                    hide-header
                                    v-bind="$t('modules.timepicker') || {}"
                                ></b-form-timepicker>
                            </b-input-group>
                        </b-form-group>
                    </b-overlay>
                    <template #modal-footer="{ hide }">
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button size="sm" variant="secondary" @click="hide('forget')">
                            {{ $t('buttons.close') }}
                        </b-button>
                        <b-button size="sm" variant="orange" @click="onSubmitEditResource">
                            {{ $t('buttons.save') }}
                        </b-button>
                    </template>
                </b-modal>
                <b-modal
                    id="delete-modal"
                    ref="delete-modal"
                    :title="$t('pages.admin.user.headers.deleteResource')"
                    :ok-title="$t('buttons.delete')"
                    :cancel-title="$t('buttons.cancel')"
                    ok-variant="danger"
                    @ok="onDelete"
                >
                    {{ $t('pages.admin.user.messages.delete.' + (selectedItems.length > 1 ? 'multi' : 'single'), {count: selectedItems.length}) }}
                </b-modal>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import userService from "../../../services/userService";
import resourceService from "../../../services/resourceService";
import keyService from "../../../services/keyService";
import mainServices from "../../../services/mainServices";
import vmService from "../../../services/vmService";
import PoolDetails from "../../../components/myResources/poolDetails";
import {EventBus} from "../../../services/EventBus";

export default {
    name: "Detail",
    components: {PoolDetails},
    props: ["id"],
    data: () => ({
        data: {},
        fields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectAll: false,
        selectedItems: [],
        viewedItem: null,
        viewedItemLoading: true,
        items: [],
        resourceTypes: [],
        resources: [],
        resourceForm: {
            resource: '',
            resourceType: 'pool',
            expireResourceType: 'date',
            expireResourceDate: '',
            expireResourceTime: '23:59',
            expireResourceDays: '7'
        },
        resourceFormState: {
            resource: null,
            expireResourceDate: null,
            expireResourceTime: null,
            expireResourceDays: null
        },
        resourceModalLoading: false,
        locale: 'de',
        tableLoading: true
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.fields = [
            {key: 'selected', label: '', sortable: false},
            {key: 'name', label: this.$t('pages.admin.user.table.resources.name'), sortable: true, sortDirection: 'asc'},
            {key: 'resourceType', label: this.$t('pages.admin.user.table.resources.type'), sortable: true, sortDirection: 'asc'},
            {key: 'public', label: this.$t('pages.admin.user.table.resources.public'), sortable: true, sortDirection: 'asc'},
            {key: 'expiresAt', label: this.$t('pages.admin.user.table.resources.expiresAt'), sortable: true, sortDirection: 'asc'},
            {key: 'createdAt', label: this.$t('pages.admin.user.table.resources.createdAt'), sortable: true},
            {key: 'actions', label: this.$t('generally.tables.actions')}
        ];
        this.loadData();
        this.loadResourceTypes();
        this.loadResources();
        this.onLoadResources();
        this.locale = mainServices.getLocale();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.$toastr.i(this.$t('generally.syncMessage'));
            this.loadData();
            this.loadResourceTypes();
            this.loadResources();
            this.onLoadResources();
        });
    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        loadData() {
            userService.get(this.id).then(response => {
                this.data = response.data;
            }).catch();
        },
        loadResources() {
            resourceService.getAll(this.id).then(response => {
                this.items = response;
                this.totalRows = this.items.length;
                this.tableLoading = false;
            })
            .catch(() => {
                 this.tableLoading = false;
            });
        },
        loadResourceTypes() {
            keyService.getResourceTypes().then(response => {
                this.resourceTypes = mainServices.convertArrayToObject(response.data, 'value');
            });
        },
        checkAll() {
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.$refs.resourceTable.selectAllRows();
                this.selectedItems = this.items;
            } else {
                this.$refs.resourceTable.clearSelected();
                this.selectedItems = [];
            }
        },
        onSelected(items) {
            this.selectAll = this.items.length === items.length;
            this.selectedItems = items;
        },
        /**
         * Copies the object to be shown and merges this with vcenter information
         * @param item
         */
        onShow(item) {
            this.viewedItem = Object.assign({}, item);
            if (this.viewedItem.resourceType === "pools") {
                this.viewedItemLoading = true;
                vmService.get(this.viewedItem.vCenterImageRef).then(response => {
                    this.viewedItem.vCenterImageRef = response.data;
                    this.viewedItemLoading = false;
                });
            }
        },
        /**
         * Converts json string and returns the string from the object that matches the language set
         * @param {string} jsonString JSON-String with language object
         * @returns {*}
         */
        convertName(jsonString){
            jsonString = mainServices.parseJSON(jsonString);
            if(typeof jsonString === "object"){
                jsonString = jsonString[this.locale]
            }
            return jsonString;
        },
        /**
         * Copies the data object, supplements it with converted time stamps and displays the editing modal
         * @param {object} item
         */
        onEdit(item) {
            this.viewedItem = Object.assign({}, item);
            this.resourceForm.expireResourceDate = this.$moment(this.viewedItem.expiresAt).format('YYYY-MM-DD');
            this.resourceForm.expireResourceTime = this.$moment(this.viewedItem.expiresAt).format('H:m');
            this.$bvModal.show("edit-modal");
        },
        onLoadResources() {
            keyService.getResources(this.resourceForm.resourceType).then(response => {
                this.resources = response.data;
                this.resourceForm.resource = '';
            }).catch(error => {
                this.resources = [];
                this.resourceForm.resource = '';
            });
        },
        onSubmitEditResource() {
            let data = {};
            data.resourceId = {
                id: this.viewedItem.id,
                type: this.viewedItem.resourceType
            };
            data.expireResourceDate = this.$moment(this.resourceForm.expireResourceDate).format('YYYY-MM-DD') + ' ' + this.resourceForm.expireResourceTime;
            if(!this.resourceModalLoading){
                this.resourceModalLoading = true;
                userService.editResource(this.id, data).then(() => {
                    this.$toastr.s(this.$t('pages.admin.user.messages.success.resourceEdited'));
                    this.$bvModal.hide("edit-modal");
                    this.resourceForm.resource = '';
                    this.resourceModalLoading = false;
                    this.loadResources();
                    this.resourceFormState = {
                        resource: null,
                        expireResourceDate: null,
                        expireResourceTime: null,
                        expireResourceDays: null
                    }
                }).catch(error => {
                    this.errorHandler(error);
                    this.resourceModalLoading = false;
                });
            }
        },
        onSubmitAddResource() {
            let data = {};
            if (this.resourceForm.resource === '' || this.resourceForm.resourceType === '') {
                this.resourceFormState.resource = false;
                this.$toastr.e(this.$t('pages.admin.user.messages.error.addResource.noResource'));
                return false;
            }
            this.resourceFormState.resource = true;
            this.resourceFormState.expireResourceDate = this.resourceForm.expireResourceDate !== '';
            this.resourceFormState.expireResourceDays = this.resourceForm.expireResourceDays !== '';
            this.resourceFormState.expireResourceTime = this.resourceForm.expireResourceTime !== '';
            if (!((this.resourceForm.expireResourceDays === '' && this.resourceForm.expireResourceDate !== '' && this.resourceForm.expireResourceTime !== '') || this.resourceForm.expireResourceDays !== '')) {
                this.$toastr.e(this.$t('pages.admin.user.messages.error.addResource.noExpire'));
                return false;
            }
            data.resourceId = {
                id: this.resourceForm.resource.id,
                type: this.resourceForm.resourceType
            };
            data.expireResourceType = this.resourceForm.expireResourceType;
            data.expireResourceDate = data.expireResourceType === 'date' ? this.$moment(this.resourceForm.expireResourceDate).format('YYYY-MM-DD') + ' ' + this.resourceForm.expireResourceTime : undefined;
            data.expireResourceDays = data.expireResourceType === 'days' ? parseInt(this.resourceForm.expireResourceDays) : undefined;
            if (!this.resourceModalLoading) {
                this.resourceModalLoading = true;
                userService.addResource(this.id, data).then(() => {
                    this.$toastr.s(this.$t('pages.admin.user.messages.success.resourceCreated'));
                    this.$bvModal.hide("add-modal");
                    this.resourceForm.resource = '';
                    this.resourceModalLoading = false;
                    this.loadResources();
                    this.resourceFormState = {
                        resource: null,
                        expireResourceDate: null,
                        expireResourceTime: null,
                        expireResourceDays: null
                    }
                }).catch(error => {
                    this.errorHandler(error);
                    this.resourceModalLoading = false;
                });
            }
        },
        onDelete() {
            userService.deleteResource(this.id, {
                    resourceIds: this.selectedItems.map(item => ( { id: item.id, type: item.resourceType }))
            }).then(() => {
                    this.$toastr.s(this.$t('pages.admin.user.messages.success.resourceDeleted'));
                    this.loadResources();
            }).catch(error => {
                this.errorHandler(error);
            });
        }
    }
};
</script>

<style scoped>

</style>
